interface Image {
  PrimKey: string;
  ID: number;
  FileName?: string;
  ResourceUrl?: string;
  Caption?: string;
  SortOrder?: number;
}

export async function getImages(
  contentItemId: number,
  cultureName: string = 'en-gb',
  galleryId?: number
): Promise<Image[]> {
  let whereStmt = `[MainItem_ID] = ${contentItemId} AND [IsImage] = 1 AND [Culture] = '${cultureName}'`;
  if (galleryId != null) whereStmt += ` AND [Gallery] = ${galleryId}`;

  let res = await fetch('/api/data', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      viewName: 'sviw_Content_ItemsFilesT',
      distinctRows: false,
      skip: 0,
      fields: [
        {
          name: 'ID',
        },
        {
          name: 'PrimKey',
        },
        {
          name: 'SortOrder',
          type: 'number',
        },
        {
          name: 'FileName',
        },
        {
          name: 'Gallery',
        },
        {
          name: 'Caption',
        },
      ],
      maxRecords: -1,
      whereClause: whereStmt,
      masterDetailString: null,
      filterString: null,
      timezoneOffset: -60,
      operation: 'retrieve',
    }),
  });

  if (res.status != 200) throw new Error('Could not retrieve images!');

  let data = await res.json();

  if (data && 'success' in data) {
    const sortedData = data.success.sort((a: Image, b: Image) => {
      const a1 = a.SortOrder ?? 1e4;
      const b1 = b.SortOrder ?? 1e4;
      return a1 > b1 ? 1 : a1 < b1 ? -1 : 0;
    });
    data.success = sortedData;
  }

  return data?.success;
}